import { ProductsInsurance, ProductsWorksheetDto } from '@src/data/types/ProductsWorksheetSchema'

function transformProductsInsuranceToApi(insurance: ProductsInsurance | null): ProductsInsurance | null {
  if (!insurance?.amount && !insurance?.police && !insurance?.provider && !insurance?.term) return null
  return insurance
}

export default function transformProductsWorksheetToApi(worksheet: ProductsWorksheetDto): ProductsWorksheetDto {
  const ret = { ...worksheet }
  ret.creditInsurance = transformProductsInsuranceToApi(ret.creditInsurance)
  ret.replacementOrGapInsurance = transformProductsInsuranceToApi(ret.replacementOrGapInsurance)
  ret.extendedWarranty = transformProductsInsuranceToApi(ret.extendedWarranty)
  return ret
}
