import { FeatureSwitch } from '.'

class FeatureFlags {
  public readonly useIncludeInsuranceAllProvinces: boolean

  constructor(featureSwitchDtos: FeatureSwitch[]) {
    const activatedFeatureSwitches = new Set(featureSwitchDtos.filter((x) => x.isActivated).map((x) => x.id))
    this.useIncludeInsuranceAllProvinces = activatedFeatureSwitches.has('UseIncludeInsuranceAllProvinces')
  }
}

export default FeatureFlags
