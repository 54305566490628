import { Box, Stack, Typography } from '@mui/material'
import { SelectComponent } from '@src/components'
import { EProductsInsurance, EProvince, SelectValueListItem } from '@src/data/types'
import { ProductsInsurance, ProductsWorksheetDto } from '@src/data/types/ProductsWorksheetSchema'
import { t } from 'i18next'
import { ChangeEvent, useEffect, useState } from 'react'
import {
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form'
import ProductsInsuranceDetails from './ProductsInsuranceDetails'

interface Props {
  insurancesProviders: Array<SelectValueListItem>
  warrantyProviders: Array<SelectValueListItem>
  register: UseFormRegister<ProductsWorksheetDto>
  setValue: UseFormSetValue<ProductsWorksheetDto>
  errors: FieldErrors<ProductsWorksheetDto>
  watch: UseFormWatch<ProductsWorksheetDto>
  getValues: UseFormGetValues<ProductsWorksheetDto>
  trigger: UseFormTrigger<ProductsWorksheetDto>
  stateIso: EProvince
}

const ProductsInsurances = ({
  insurancesProviders,
  warrantyProviders,
  register,
  setValue,
  errors,
  watch,
  getValues,
  trigger,
  stateIso,
}: Props) => {
  let defaultInsuranceProvider: string = ''
  const defaultExtendedWarrantyProvider = watch('extendedWarranty.provider') ?? ''
  getValues(['replacementOrGapInsurance', 'creditInsurance']).forEach((e) => {
    if (e?.provider && !defaultInsuranceProvider) {
      defaultInsuranceProvider = e?.provider
    }
  })

  const [insuranceProvider, setInsuranceProvider] = useState<string>(defaultInsuranceProvider)
  const [extendedWarrantyProvider, setExtendedWarrantyProvider] = useState<string>(defaultExtendedWarrantyProvider)
  const [insuranceProviderError, setInsuranceProvideError] = useState<string | undefined>(undefined)
  const [extendedWarrantyError, setExtendedWarrantyError] = useState<string | undefined>(undefined)
  const [worksheet, setWorksheet] = useState<ProductsWorksheetDto>(getValues())

  const anyFieldProvided = (values: ProductsInsurance | null) => {
    if (!values) return false
    return !!values.amount || !!values.term || !!values.police
  }

  useEffect(() => {
    const validate = (key: EProductsInsurance, provider: string | null, fieldProvided: boolean) => {
      if (fieldProvided) {
        setValue(`${key}.provider`, provider)
      } else {
        setValue(`${key}.provider`, null)
        // eslint-disable-next-line no-void
        void trigger(key)
      }
    }

    const anyReplacementOrGapField = anyFieldProvided(worksheet.replacementOrGapInsurance)
    const anyCreditField = anyFieldProvided(worksheet.creditInsurance)
    const anyExtendedField = anyFieldProvided(worksheet.extendedWarranty)

    validate(EProductsInsurance.CreditInsurance, insuranceProvider, anyCreditField)

    validate(EProductsInsurance.ReplacementOrGapInsurance, insuranceProvider, anyReplacementOrGapField)

    validate(EProductsInsurance.ExtendedWarranty, extendedWarrantyProvider, anyExtendedField)

    const error =
      (anyReplacementOrGapField || anyCreditField) && !insuranceProvider ? 'common.errors.required' : undefined

    setInsuranceProvideError(error)
    setExtendedWarrantyError(anyExtendedField && !extendedWarrantyProvider ? 'common.errors.required' : undefined)
    const getPrime = (value: number | null | undefined) => {
      const amount = Number(value)
      return Number.isNaN(amount) ? 0 : amount
    }
    const amount =
      getPrime(worksheet.replacementOrGapInsurance?.amount) +
      getPrime(worksheet.creditInsurance?.amount) +
      getPrime(worksheet.extendedWarranty?.amount)

    setValue('amountRequested', amount)
  }, [insuranceProvider, extendedWarrantyProvider, worksheet, setValue, trigger, getValues])

  useEffect(() => {
    const { unsubscribe } = watch((values) => {
      setWorksheet(values as ProductsWorksheetDto)
    })
    return () => unsubscribe()
  }, [watch])

  return (
    <Stack direction={{ lg: 'row', md: 'column' }} spacing={10} justifyContent="flex-start">
      <Stack direction="column" sx={{ height: '100%' }}>
        <Typography variant="h5">{t('worksheet.insurance')}</Typography>
        <Stack direction="column" justifyContent="space-around" sx={{ flexGrow: 1, marginTop: 2 }}>
          <SelectComponent
            error={insuranceProviderError}
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setInsuranceProvider(e.target.value)
            }}
            items={insurancesProviders}
            label={t('worksheet.provider') as string}
            value={worksheet.creditInsurance?.provider || worksheet.replacementOrGapInsurance?.provider}
          />
          <Stack direction="row" spacing={2}>
            <Box>
              <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1, mt: 1 }}>
                {stateIso === EProvince.quebec ? t('worksheet.replacementInsurance') : t('worksheet.gapInsurance')}
              </Typography>
              <ProductsInsuranceDetails
                register={register}
                insurance={EProductsInsurance.ReplacementOrGapInsurance}
                errors={errors}
              />
            </Box>
            <Box>
              <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1, mt: 1 }}>
                {t('worksheet.creditInsurance')}
              </Typography>
              <ProductsInsuranceDetails
                register={register}
                insurance={EProductsInsurance.CreditInsurance}
                errors={errors}
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="column" sx={{ height: '100%' }}>
        <Typography variant="h5">{t('worksheet.extendedWarranty')}</Typography>
        <Stack direction="column" justifyContent="space-around" sx={{ flexGrow: 1, marginTop: 2 }}>
          <SelectComponent
            items={warrantyProviders}
            error={extendedWarrantyError}
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setExtendedWarrantyProvider(e.target.value)
            }}
            label={t('worksheet.provider') as string}
            value={worksheet.extendedWarranty?.provider}
          />
          <Typography variant="subtitle2" fontWeight="bold" sx={{ mt: 3.75 }} />
          <ProductsInsuranceDetails
            register={register}
            insurance={EProductsInsurance.ExtendedWarranty}
            errors={errors}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ProductsInsurances
