import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Applicant, EFinancingProgram, buildDraftCreditApplicationSchema } from '../../../data/types'
import ActionsDialog from '../../../components/ActionsDialog'
import { reportErrorToConsole } from '../../../services/error-logger'
import ApplicantExpenseForm from '../../EditCreditApplicationPage/components/CreditApplicationForm/components/Applicant/components/applicantExpenseComponent'
import { appSelectors } from '../../../data/store/AppStore'
import { useAppSelector } from '../../../data/store'
import { PageError } from '../../../components'

type Props = {
  applicant: Applicant | undefined
  open: boolean
  title: string
  onConfirm: (data: Applicant) => void
  onCancel: () => void
  financingProgramId: EFinancingProgram
}

const EditExpenseDialog = ({ applicant, onConfirm, onCancel, open, title, financingProgramId }: Props) => {
  const error = useAppSelector(appSelectors.getBusinessError)
  const {
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Applicant>({
    mode: 'onBlur',
    defaultValues: applicant,
    resolver: yupResolver(buildDraftCreditApplicationSchema()),
  })

  React.useEffect(() => {
    reset(applicant)
  }, [applicant, reset])

  return (
    <ActionsDialog
      title={title}
      onCancel={onCancel}
      onConfirm={handleSubmit(onConfirm, reportErrorToConsole)}
      open={open}
    >
      <PageError errors={error} />
      <ApplicantExpenseForm
        name="expenses"
        register={register}
        watch={watch}
        setValue={setValue}
        errors={errors.expenses}
        showDetailedForm
        financingProgramId={financingProgramId}
        editDisabled
      />
    </ActionsDialog>
  )
}
export default React.memo(EditExpenseDialog)
