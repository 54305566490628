import React from 'react'
import { Divider } from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Applicant, buildDraftCreditApplicationSchema } from '../../../data/types'
import { ActionsDialog } from '../../../components'
import IncomeForm from './IncomeForm'
import OtherIncomeForm from './OtherIncomeForm'
import { reportErrorToConsole } from '../../../services/error-logger'

type Props = {
  open: boolean
  title: string
  defaultValue: Applicant | undefined
  onConfirm: (data: Applicant) => void
  onCancel: () => void
  disableConfirm?: boolean
}

const EditIncomesDialog = ({ defaultValue, onConfirm, onCancel, open, title, disableConfirm }: Props) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<Applicant>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: defaultValue,
    resolver: yupResolver(buildDraftCreditApplicationSchema()),
  })

  React.useEffect(() => {
    reset(defaultValue)
  }, [defaultValue, reset])

  const { fields: otherIncomesFields, remove: otherIncomesRemove } = useFieldArray({
    name: 'otherIncomes',
    control,
  })

  const { fields: currentJobsFields, remove: currentJobsRemove } = useFieldArray({
    name: 'currentJobs',
    control,
  })

  return (
    <ActionsDialog
      title={title}
      open={open}
      onCancel={onCancel}
      onConfirm={handleSubmit(onConfirm, reportErrorToConsole)}
      disabled={disableConfirm}
    >
      {currentJobsFields.length !== 0 && (
        <Divider textAlign="left" sx={{ marginTop: 1 }}>
          {t('editCreditApplication.income.job.label')}
        </Divider>
      )}
      {currentJobsFields.map((job, index) => (
        <IncomeForm
          index={index}
          register={register}
          error={errors?.currentJobs}
          key={job.id}
          onDelete={() => currentJobsRemove(index)}
        />
      ))}
      {otherIncomesFields.length !== 0 && (
        <Divider textAlign="left" sx={{ marginTop: 1 }}>
          {t('editCreditApplication.income.other.label')}
        </Divider>
      )}
      {otherIncomesFields.map((otherIncome, index) => (
        <OtherIncomeForm
          index={index}
          register={register}
          formControl={control}
          error={errors?.otherIncomes}
          key={otherIncome.id}
          onDelete={() => otherIncomesRemove(index)}
        />
      ))}
    </ActionsDialog>
  )
}

export default React.memo(EditIncomesDialog)
