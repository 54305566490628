import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../Home/HomePage'
import BrowseCreditApplicationsPage from '../BrowseCreditApplicationsPage'
import CreateCreditApplicationPage from '../EditCreditApplicationPage/CreateCreditApplicationPage'
import LoginPage from '../Home/LoginPage'
import CreditTaskDashboardPage from '../CreditTaskDashboardPage'
import AuthPage from '../Auth'
import LoadCreditApplication from './LoadCreditApplication'
import RouteGuard from './RouteGuard'
import NotFoundPage from './NotFoundPage'
import Reports from '../Reports/Reports'
import ManageTestCase from '../TestCaseManagement/ManageTestCasePage'
import PayoutThatSkippedIncomeValidation from '../Reports/PayoutThatSkippedIncomeValidation'
import FeaturesSwitchForm from '../FeatureSwitch/FeaturesSwitchForm'

const TopLevelRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="home" element={<Home />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="creditTasksDashboard" element={<CreditTaskDashboardPage />} />
      <Route path="auth-page" element={<AuthPage />} />
      <Route path="applications">
        <Route path="browse" element={<BrowseCreditApplicationsPage />} />
        <Route element={<RouteGuard requiredPermission="canEditCreditApp" />}>
          <Route path=":financingProgramId/add" element={<CreateCreditApplicationPage />} />
        </Route>
        <Route path=":financingProgramId/:id/*" element={<LoadCreditApplication />} />
      </Route>
      <Route path="reports">
        <Route path="payoutThatSkippedIncomeValidation" element={<PayoutThatSkippedIncomeValidation />} />
        <Route path="" element={<Reports />} />
      </Route>
      <Route path="featureSwitch" element={<FeaturesSwitchForm />} />
      <Route path="manageTestCases" element={<ManageTestCase />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default TopLevelRoutes
